export const dragStart = (e) => {
  document.documentElement.classList.add('drag-active')
  window.parent.document.documentElement.classList.add('drag-active')
  document.body.style.cursor = 'grab'
  window.parent.document.body.style.cursor = 'grab'
}

export const dragEnd = (e) => {
  document.documentElement.classList.remove('drag-active')
  window.parent.document.documentElement.classList.remove('drag-active')
  document.body.style.cursor = ''
  window.parent.document.body.style.cursor = ''
  document.documentElement.dispatchEvent(new Event('sortable-drag-end'))
}

export const isDragActive = () => {
  return document.documentElement.classList.contains('drag-active')
}

export const getDraggedEl = () => {
  return document.querySelector('.sortable-drag')
}

export const runAfterDragEnd = (callback) => {
  if (isDragActive()) {
    document.documentElement.addEventListener('sortable-drag-end', callback)
  } else {
    callback()
  }
}
